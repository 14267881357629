/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

exports.onClientEntry = () => {
  const script = document.createElement("script")

  script.async = true
  script.src = "https://www.googletagmanager.com/gtag/js?id=UA-157867026-1"

  script.onload = function() {
    window.dataLayer = window.dataLayer || []

    function gtag() {
      window.dataLayer.push(arguments)
    }

    gtag("js", new Date())

    gtag("config", "UA-157867026-1")
  }

  document.body.appendChild(script)
}
