import { createMuiTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    type: "dark",
  },
  typography: {
    fontFamily: ["sans-serif"],
    button: {
      textTransform: "none",
    },
  },
})

export default theme
